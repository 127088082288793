// import './apm';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import('./bootstrap').then(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  bootstrap => bootstrap.default(() => null),
  reason => {
    console.log('Error: ', reason);
  },
);
